<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="9">
      <v-card>
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="results.content_url + results.school_logo"
                  max-height="50px"
                  max-width="50px"
                  alt="logo"
                  contain
                  class="me-3"
                  style="border-radius: 10%"
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ results.school_name }}
                </span>
              </div>
              <span class="d-block">اسم الزبون: {{ billAccountData.customer_name }}</span>
              <span class="d-block">الهاتف: {{ billAccountData.customer_mobile }}</span>
              <!-- <span class="d-block">الصف والشعبة: {{ billAccountData.account_division_current.class_name + " - " +
                  billAccountData.account_division_current.leader
              }}</span>
              <span class="d-block">رقم الهاتف: {{ billAccountData.student_mobile }}</span> -->
            </div>
            <!-- Right Content -->
            <div>
              <!-- <p class="font-weight-medium text-xl text--primary mb-4">
                Invoice #5s545s
              </p> -->
              <!-- <p class="mb-2">
                <span>رقم الوصل: </span>
                <span class="font-weight-semibold">#{{ new Date().getMilliseconds() }}</span>
              </p> -->
              <p class="mb-2">
                <span>تاريخ الاصدار: </span>
                <span class="font-weight-semibold">{{ dateString }}</span>
              </p>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <!-- <v-card-text class="py-9 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div class="mb-8 mb-sm-0">
              <p class="font-weight-semibold payment-details-header">
                Invoice To:
              </p>
              <p class="mb-1">
                client.name
              </p>
              <p class="mb-1">
                company
              </p>
              <p class="mb-1">
                address
              </p>
              <p class="mb-1">
                contact
              </p>
              <p class="mb-0">
                companyEmail
              </p>
            </div>
            <div>
              <p class="font-weight-semibold payment-details-header">
                Bill To:
              </p>
              <table>
                <tr>
                  <td class="pe-6">
                    Total Due:
                  </td>
                  <td>
                    Due
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    Bank Name:
                  </td>
                  <td>
                    Bank
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    Country:
                  </td>
                  <td>
                    Country
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    IBAN:
                  </td>
                  <td>
                    IBAN
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    SWIFT Code:
                  </td>
                  <td>
                    SWIFT
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text> -->

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>نوع الخدمة</th>
                <th>تاريخ الانشاء</th>
                <th>المبلغ الكلي</th>
                <th>المبلغ المدفوع</th>
                <th>الخصم</th>
                <th>المتبقي</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in responseDB" :key="item._id">
                <td class="text-no-wrap">
                  {{ item.service_name }}
                </td>
                <td class="text-no-wrap">
                  {{ item.date }}
                </td>
                <td class="text-no-wrap">
                  {{ numberWithComma(item.total_amount) }}
                </td>
                <td>
                  {{ numberWithComma(item.payment_amount) }}
                </td>
                <td>
                  {{ numberWithComma(item.discount_amount) }}
                </td>
                <td>
                  {{ numberWithComma(item.remaining) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Total -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <div>
              <table>
                <tr>
                  <td class="pe-16">المبلغ:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ numberWithComma(this.statistic.total_amount) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">المدفوع:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ numberWithComma(this.statistic.payment_amount) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">الخصم:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ numberWithComma(this.statistic.discount_amount) }}
                  </th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">المتبقي:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ numberWithComma(this.statistic.remaining) }}
                  </th>
                </tr>
              </table>
            </div>
            <!-- <div class="mb-2 mb-sm-0">
              <p class="mb-1">
                <span class="font-weight-semibold">المحاسب</span>
              </p>
              <p>{{ results.account_name }}</p>
            </div> -->
          </div>
        </v-card-text>
        <!--
        <v-divider></v-divider>

        <v-card-text class="px-8 py-6">
          <p class="mb-0">
            <span class="font-weight-semibold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future
              freelance projects. Thank You!</span>
          </p>
        </v-card-text> -->
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card>
        <v-card-text>
          <!-- <v-btn color="primary" class="mb-3" block>
            <v-icon class="me-2" left>
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Send Invoice</span>
          </v-btn> -->
          <!-- <v-btn class="mb-3" color="secondary" block outlined>
            Download
          </v-btn> -->
          <v-btn class="mb-3" color="secondary" block outlined @click="printInvoice"> طباعة </v-btn>
          <!-- <v-btn class="mb-3" color="secondary" block outlined>
            Edit Invoice
          </v-btn>
          <v-btn color="success" block>
            <v-icon class="me-2" left>
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
            <span>Add Payment</span>
          </v-btn> -->
        </v-card-text>
      </v-card>
    </v-col>

    <!-- invoice send drawer -->
    <!-- <invoice-sidebar-send-invoice v-model="isInvoiceSendSidebarActive"></invoice-sidebar-send-invoice> -->

    <!-- invoice add payment drawer -->
    <!-- <invoice-sidebar-add-payment v-model="isAddPaymentSidebarActive"></invoice-sidebar-add-payment> -->
  </v-row>
</template>

<script>
import salary_other_invoice_api from '@/api/salary_other_invoice'
import { mdiCurrencyUsd, mdiSendOutline } from '@mdi/js'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import numberWithComma from '@/constant/number'

export default {
  //   components: {
  //     InvoiceSidebarSendInvoice,
  //     InvoiceSidebarAddPayment,
  //   },

  data () {
    return {
      invoiceData: null,
      paymentDetails: {},
      billInfo: {
        _id: null,
        student_id: null,
        desc: null,
        service_type: null,
        school_id: null,
        is_deleted: null,
        createdAt: null,
        updatedAt: null,
        salaryAmount: null,
        discountAmount: null,
        paymentAmount: null,
        currency: null,
        currencySymbol: ' IQD',
        nextPaymentDate: null,
        remainingAll: 0,
        student_name: null,
        service_name: null,
      },

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },

      results: {},

      billAccountData: {},

      dateString: null,

      responseDB: [],

      statistic: {
        currency: 'IQD',
        currencySymbol: ' IQD',
        discount: null,
        payment: null,
        salary: null,
        remaining: null,
      },
    }
  },

  async created () {
    this.results = JSON.parse(localStorage.getItem('results'))

    this.billAccountData = JSON.parse(localStorage.getItem('anotherBillInvoice'))

    const response = await salary_other_invoice_api.get({
      isDeleted: false,
      isPaid: null,
      start_date: null,
      end_date: null,
      customer_id: this.billAccountData.customer_id,
    })

    this.statistic = response.data.results.otherData
    this.responseDB = response.data.results.data

    const date = new Date()

    this.dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
  },

  methods: {
    printInvoice () {
      window.print()
    },
    numberWithComma,
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-preview,
.invoice-total,
.purchased-items-table,
.invoice-header,
.v-data-table {
  color: #000 !important;
  font-weight: bold;
}

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
