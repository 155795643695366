import axios from 'axios'

class salaryOtherInvoiceApi {
  async get({ customer_id, isDeleted, isPaid, start_date, end_date, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(
      `counting/otherSalary/invoice/customer_id/${customer_id}?search=${search}&isDeleted=${isDeleted}&isPaid=${isPaid}&start_date=${start_date}&end_date=${end_date}`,
    )
    return response
  }

  async add({ customer_id, desc, service_type, date, price, payment_amount, discount_amount, received_by }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('counting/otherSalary/invoice', {
      customer_id,
      desc,
      service_type,
      date,
      price,
      payment_amount,
      discount_amount,
      received_by,
    })
    return response
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.delete(`counting/otherSalary/invoice/id/${id}`)
    return response
  }
}

export default new salaryOtherInvoiceApi()
